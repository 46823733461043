<template>
  <vs-table :data="tableData">
    <template slot="thead">
      <vs-th>Channel ID</vs-th>
      <vs-th>Channel Name</vs-th>
    </template>

    <template v-if="tableData.length">
      <vs-tr :key="indextr" v-for="(tr, indextr) in tableData">
        <vs-td :data="tableData[indextr].groupId" class="cursor-pointer">{{
          tableData[indextr].groupId
        }}</vs-td>

        <vs-td :data="tableData[indextr].group_name" class="cursor-pointer">{{
          tableData[indextr].group_name
        }}</vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const groupsRepository = RepositoryFactory.get("groups");
export default {
  data() {
    return {
      tableData: []
    };
  },
  methods: {
    async getAllGroups() {
      this.$vs.loading();
      const payload = {
        communityData: {
          communityName: currentCommunityName.communityName
        },
        userData: {
          userID: this.$store.state.user.userID
        },
        paging: {
          limit: 100,
          offset: 0
        }
      };

      const { data } = await groupsRepository.getAllGroups(payload);

      if (data) {
        this.$vs.loading.close();
        this.tableData = data;
      }
    }
  },
  mounted() {
    this.getAllGroups();
  }
};
</script>
