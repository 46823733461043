<template>
  <div>
    <div class="vx-col md:flex mb-base">
      <vs-button
        color="primary"
        type="filled"
        icon-pack="feather"
        icon="icon-download"
        @click="exportToExcel"
        >Download Format</vs-button
      >
    </div>
    <div class="vx-col Excel-parent-div md:flex w-full">
      <ImportExcel :onSuccess="loadDataInTable" />
      <div class="md:w-3/5 flex excel-sttus-right flex-col justify-between">
        <vs-table class="excel-tabl-resp" max-items="3" :data="tableData">
          <template slot="thead">
            <vs-th>File Name</vs-th>
            <vs-th>Status</vs-th>
            <!-- <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{
            heading
            }}</vs-th>-->
          </template>

          <template v-if="tableData.length && header.length">
            <vs-tr>
              <vs-td :data="fileName" class="cursor-pointer">
                {{ fileName }}
              </vs-td>

              <vs-td :data="status" class="cursor-pointer">{{ status }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-button
          v-if="tableData.length && header.length"
          class="mx-2 w-full"
          color="primary"
          type="filled"
          @click="validateInvites"
          >Upload Data</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ImportExcel from "@/Core/Views/CommonPlugins/import-excel/ImportExcel";
// import XLSX from "xlsx";
import { currentCommunityName } from "@/Core/config/communityDetails.js";
import EmailSMSMixin from "@/Client/Kotak/mixins/EmailSMSMixin";
import { RepositoryFactory } from "@/Core/Apicall/RepositoryFactory";
const userRepository = RepositoryFactory.get("user");
import jwt from "jsonwebtoken";
import * as emailer from "@/Client/Kotak/emailer/inviteEmailer.js";

export default {
  mixins: [EmailSMSMixin],
  components: {
    ImportExcel,
  },
  data() {
    return {
      token: {},
      tableData: [],
      header: [],
      sheetName: "",
      fileName: "",
      status: "File Ready",
      headerVal: ["user_name", "mobile_no", "email", "group_ids"],
      downloadFileName: "invite_format",
      selectedFormat: "xlsx",
      cellAutoWidth: true,
      content: ``,
    };
  },
  created() {
    let token = localStorage.getItem("token");
    this.token = jwt.decode(token);
  },
  computed: {
    magittUrl() {
      return this.baseUrlMagitt;
    },
  },
  methods: {
    loadDataInTable({ results, header, meta }) {
      this.header = header;
      this.tableData = results;
      this.sheetName = meta.sheetName;
      this.fileName = meta.fileName;
      this.tableData.forEach((element) => {
        if (typeof element.mobile_no == "string") {
          element.mobile_no = Number(element.mobile_no.replace(/ /g, ""));
        }
        element.status = 1;
        element.created_by = this.token.username;
      });
    },
    async validateInvites() {
      this.$vs.loading();
      const payload = {
        communityData: {},
        communityName: currentCommunityName.communityName,
        mobiles: this.tableData.map((x) => x.mobile_no).join(","),
      };

      const  {data}  = await userRepository.getUsersByMobileNumber(payload);
      if(data==false){
        this.$vs.loading.close();

        return this.$swal({
          icon: "error",
          title: "Invalid Data formate",
     
        })
      }
      if (data.Data.length > 0) {
        this.$vs.loading.close();
        const mobilenumbers = data.Data.map((x) => x.usersDetails_Mobile);
        let tableData = "";
        let header = `
          <div class="cust-exceltble">
            <table class="w-full">
              <tr>
                <th class="text-center mb-2 cust-border-left">SERIAL NO</th>
                <th class="text-center mb-2 ">DUPLICATE</th>
              </tr>`;
        let footer = `
            </table>
          </div>`;

        for (let index = 0; index < mobilenumbers.length; index++) {
          tableData += `
            <tr>
              <td class="pd-10 cust-border-left">${index + 1}</td>
              <td class="pd-10 "> ${mobilenumbers[index]}</td>
            </tr>
         `;
        }
        this.$swal({
          icon: "error",
          title: "Duplicate mobile numbers found",
          html: `${header}${tableData}${footer}`,
        });
      } else {
        this.$vs.loading.close();
        this.uploadInvites();
      }
    },
    async uploadInvites() {
      this.content = `
      <html>
          <body>
            <p>Dear Sir/ Ma’am,</p>
            <p>You have been an esteemed customer of Kotak Mahindra Bank.</p>
            <p>We would like to extend our gratitude by sharing with you, our Senior Economist’s view on Global and Local markets.</p>
            <p>From Daily Market Snippets, Weekly Audios & Economy View to Periodical Interviews & Exclusive Decks; all of this will be available on a regular basis on Email and WhatsApp that will keep you updated.</p>
            <p>You can register by clicking on the link below.</p>
            <p> Registration Link:  </p>
            <p>
            Regards<br/>
            Team Treasury<br/>
            <br>
            </p><br/>
          </body>
        </html>
      `;
      this.$vs.loading();
      const payload = {
        communityData: {},
        communityName: currentCommunityName.communityName,
        excelData: this.tableData
      };

      const { data } = await userRepository.addExcelInvites(payload);

      if (data) {
        this.$vs.loading.close();
        //SEND MAIL To All USER
        this.sendEmail(emailer.emailHTML(this.content),'Invitation',this.tableData.map(x=>{return x.email}));
        this.$swal({
          icon: "success",
          title: "Data Uploaded",
        }).then(() => {
          this.$router
            .push({
              name: "page-viewmembers",
              params: { filter: "Invited users" },
            })
            .catch(() => {});
        });
      }else{
        this.$vs.loading.close();
      }
    },

    exportToExcel() {
      import("@/Core/vendor/Export2Excel").then((excel) => {
        const list = [];
        const data = this.formatJson(this.headerVal, list);
        excel.export_json_to_excel({
          header: this.headerVal,
          data,
          filename: this.downloadFileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j];
        })
      );
    },
  },
};
</script>

<style>
@media (max-width: 425px) {
  .Excel-parent-div {
    display: contents !important;
  }
}
.cust-exceltble {
  margin-top: 10px;
  height: 150px;
  overflow-y: auto;
  border: 2px solid #ededed;
}
</style>

<!--<vx-card>
    <vs-table stripe pagination :max-items="10" search >
    <template slot="header">
        <h4>{{ sheetName }}</h4>
    </template>

    <template slot="thead">
        <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
    </template>

    <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
        <vs-td :data="col" v-for="col in data[indextr]" :key="col">
            {{ col }}
        </vs-td>
        </vs-tr>
    </template>
    </vs-table>
</vx-card>-->
